import React from 'react';
import type { LoginFormHelpers, LoginFormValues } from 'components/organisms';
import { LoginForm } from 'components/organisms';
import { AuthenticationTemplate } from 'components/templates';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

export interface LoginPageProps {
    initialLoginFormValues: LoginFormValues;
    onLoginFormSubmit: (values: LoginFormValues, helpers: LoginFormHelpers) => void;
}

const LoginPage: React.FC<LoginPageProps> = props => (
    <AuthenticationTemplate>
        <Container>
            <Row>
                <Col className="mx-auto" md="9" lg="8">
                    <h1 className="mb-3">Welcome back!</h1>
                    <LoginForm
                        initialValues={props.initialLoginFormValues}
                        onSubmit={props.onLoginFormSubmit}
                    />
                    <div className="text-center my-3">
                        <Link className="small" to="/register">
                            Need an account? Sign up!
                        </Link>
                    </div>
                </Col>
            </Row>
        </Container>
    </AuthenticationTemplate>
);

export default LoginPage;
