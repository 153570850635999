import React from 'react';
import { RoundedHero } from '@typedbootstrap/ui-kit-pro-components';
import { DefaultTemplate } from 'components/templates';
import { Container } from 'reactstrap';

export interface HomePageProps {}

const HomePage: React.FC<HomePageProps> = () => (
    <DefaultTemplate>
        <RoundedHero
            bg="primary"
            color="light"
            title="Home Page"
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis malesuada urna. Morbi pretium quam nisi, a consectetur vel."
            dark
        />
        <section className="py-10">
            <Container>
                <p>This is a basic content page.</p>
            </Container>
        </section>
    </DefaultTemplate>
);

export default HomePage;
