import React from 'react';
import {
    LayoutDefault,
    LayoutDefaultContent,
    LayoutDefaultFooter
} from '@typedbootstrap/ui-kit-pro-components';
import { MinimalFooter, Topnav } from 'components/organisms';

export interface DefaultTemplateProps {
    children?: React.ReactNode;
}

const DefaultTemplate: React.FC<DefaultTemplateProps> = props => {
    const { children } = props;

    return (
        <LayoutDefault>
            <LayoutDefaultContent>
                <main>
                    <Topnav />
                    {children}
                </main>
            </LayoutDefaultContent>
            <LayoutDefaultFooter>
                <MinimalFooter />
            </LayoutDefaultFooter>
        </LayoutDefault>
    );
};

export default DefaultTemplate;
