import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Collapse, Nav, NavItem, NavLink, Navbar, NavbarBrand, NavbarToggler } from 'reactstrap';

export interface TopnavProps {}

const Topnav: React.FC<TopnavProps> = () => {
    const [isOpen, setIsOpen] = useState(false);

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const checkScroll = () => {
            if (!isScrolled && window.scrollY > 10) {
                setIsScrolled(true);
            } else if (isScrolled && window.scrollY <= 10) {
                setIsScrolled(false);
            }
        };

        window.addEventListener('isScrolled', checkScroll);

        return () => {
            window.removeEventListener('isScrolled', checkScroll);
        };
    }, [isScrolled]);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <Navbar color="primary" expand="lg" fixed="top" container dark>
            <NavbarBrand tag={Link} to="/">
                TypedBootstrap
            </NavbarBrand>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
                <Nav className="ms-auto me-lg-2" navbar>
                    <NavItem>
                        <NavLink tag={Link} to="/">
                            Home
                        </NavLink>
                    </NavItem>
                </Nav>
            </Collapse>
        </Navbar>
    );
};

export default Topnav;
