import React from 'react';
import {
    LayoutAuthentication,
    LayoutAuthenticationContent
} from '@typedbootstrap/ui-kit-pro-components';
import { Col, Container, Row } from 'reactstrap';

export interface AuthenticationTemplateProps {
    children?: React.ReactNode;
}

const AuthenticationTemplate: React.FC<AuthenticationTemplateProps> = props => {
    const { children } = props;

    return (
        <div className="bg-white">
            <LayoutAuthentication>
                <LayoutAuthenticationContent>
                    <main>
                        <Container className="ps-md-0" fluid>
                            <Row className="g-0">
                                <Col
                                    className="d-none d-md-flex bg-primary bg-img-cover"
                                    style={{
                                        backgroundImage: `url('assets/images/backgrounds/bg-auth.jpg')`
                                    }}
                                    md="4"
                                    lg="6"></Col>
                                <Col md="8" lg="6">
                                    <div className="vh-100 d-flex align-items-center py-5">
                                        {children}
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </main>
                </LayoutAuthenticationContent>
            </LayoutAuthentication>
        </div>
    );
};

export default AuthenticationTemplate;
