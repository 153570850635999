import React from 'react';
import type { PasswordRecoveryFormHelpers, PasswordRecoveryFormValues } from 'components/organisms';
import { PasswordRecoveryForm } from 'components/organisms';
import { AuthenticationTemplate } from 'components/templates';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

export interface ForgotPasswordPageProps {
    initialPasswordRecoveryFormValues: PasswordRecoveryFormValues;
    onPasswordRecoveryFormSubmit: (
        values: PasswordRecoveryFormValues,
        helpers: PasswordRecoveryFormHelpers
    ) => void;
}

const ForgotPasswordPage: React.FC<ForgotPasswordPageProps> = props => (
    <AuthenticationTemplate>
        <Container>
            <Row>
                <Col className="mx-auto" md="9" lg="8">
                    <h1 className="mb-3">Password Recovery</h1>
                    <div className="mb-3 text-muted">
                        Enter your email address and we will send you a link to reset your password.
                    </div>
                    <PasswordRecoveryForm
                        initialValues={props.initialPasswordRecoveryFormValues}
                        onSubmit={props.onPasswordRecoveryFormSubmit}
                    />
                    <div className="text-center my-3">
                        <Link className="small" to="/register">
                            Need an account? Sign up!
                        </Link>
                    </div>
                </Col>
            </Row>
        </Container>
    </AuthenticationTemplate>
);

export default ForgotPasswordPage;
