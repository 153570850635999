import React from 'react';
import { LayoutError, LayoutErrorContent } from '@typedbootstrap/ui-kit-pro-components';

export interface ErrorTemplateProps {
    children?: React.ReactNode;
}

const ErrorTemplate: React.FC<ErrorTemplateProps> = props => {
    const { children } = props;

    return (
        <div className="bg-white">
            <LayoutError>
                <LayoutErrorContent>
                    <main>{children}</main>
                </LayoutErrorContent>
            </LayoutError>
        </div>
    );
};

export default ErrorTemplate;
