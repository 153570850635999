import React from 'react';
import { Footer } from '@typedbootstrap/ui-kit-pro-components';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';

export interface MinimalFooterProps {}

const MinimalFooter: React.FC<MinimalFooterProps> = () => {
    return (
        <Footer bg="dark" className="p-5 mt-auto" dark>
            <Container>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="text-muted">Copyright &copy; Your Website 2024</div>
                    <div>
                        <Link to="/">Privacy Policy</Link>
                        &middot;
                        <Link to="/">Terms &amp; Conditions</Link>
                    </div>
                </div>
            </Container>
        </Footer>
    );
};

export default MinimalFooter;
