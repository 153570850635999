import React from 'react';
import type { RegisterFormHelpers, RegisterFormValues } from 'components/organisms';
import { RegisterForm } from 'components/organisms';
import { AuthenticationTemplate } from 'components/templates';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

export interface RegisterPageProps {
    initialRegisterFormValues: RegisterFormValues;
    onRegisterFormSubmit: (values: RegisterFormValues, helpers: RegisterFormHelpers) => void;
}

const RegisterPage: React.FC<RegisterPageProps> = props => (
    <AuthenticationTemplate>
        <Container>
            <Row>
                <Col className="mx-auto" md="9" lg="8">
                    <h1 className="mb-3">Create Account</h1>
                    <RegisterForm
                        initialValues={props.initialRegisterFormValues}
                        onSubmit={props.onRegisterFormSubmit}
                    />
                    <div className="text-center my-3">
                        <Link className="small" to="/login">
                            Have an account? Go to login
                        </Link>
                    </div>
                </Col>
            </Row>
        </Container>
    </AuthenticationTemplate>
);

export default RegisterPage;
